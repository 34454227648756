@import "../../../../../theme/index.module.scss";

.Container {
  position: relative;
  background: white;
  display: flex;
  padding: 1.5em;

  @include bp($medium) {
    box-shadow: unset;
    background: transparent;
    display: block;
    padding: 0;
  }
}

.Image {
  width: 60%;
  height: 90px;
  background-color: #eee !important;
  background-size: cover !important;
  background-position: center !important;

  @include bp($medium) {
    width: 100%;
    height: 190px;
  }
}

.circle {
  margin-top: -200px;
}

.Content {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 1em;

  @include bp($medium) {
    background: white;
    width: 90%;
    margin: -1.5em auto 0;
    align-items: center;
    padding: 1.5em 0;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  }
}

.Date {
  background: #333333;
  color: white;
  padding: .3em .5em;
  display: flex;
  justify-content: center;

  a {
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 800;
    color: white;
  }

  @include bp($medium) {
    position: absolute;
    margin-top: -2em;
  }
}

.Title {
  position: relative;
  margin-top: .5em;

  h1 {
    font-size: 18px;
    line-height: 22px;
    text-transform: capitalize;
    margin: 0;
    color: black;
  }

  &:after {
    content: "";
    width: 70px;
    height: 1px;
    background: red;
    position: absolute;
    margin: .75em 0;
  }

  @include bp($medium) {
    h1 {
      padding: 0 1.5em;
      text-align: center;
    }

    &:after {
      left: 0;
      right: 0;
      margin: 1em auto;
    }
  }
}

.Category {
  margin-top: 1.2em;
  position: relative;

  div {
    font-size: 12px;
    letter-spacing: 0.2em;
    font-weight: 800;
    color: black;
  }

  p {
    font-size: 12px;
    letter-spacing: 0.2em;
    text-transform: capitalize;
    color: black;
  }

  @include bp($medium) {
    display: flex;
    align-items: center;
    margin-top: 2.2em;
    flex-direction: column;

    div {
      &:after {
        content: '';
        position: absolute;
        width: 5px;
        height: 5px;
        background: black;
        border-radius: 100%;
        margin: .45em;
      }
    }

    p {
      margin-left: 1.5em;
    }
  }
  
  @include bp($large) {
    flex-direction: row;
  }
}

.Paragraph {
  display: none;
  width: 100%;
  margin: 2em 0 1em;

  p {
    font-size: 16px;
    letter-spacing: 1px;
    text-align: center;
    color: black;

    a {
      color: #E50100;
    }
  }

  @include bp($medium) {
    display: block;
    padding: 0 1.5em;
    color: $black;
  }

  mark {
    background: #e6020182;
    padding: 2px 5px;
  }
}

.Button {
  display: none;

  @include bp($medium) {
    display: flex;
    color: #E50100;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 15px;
    align-items: baseline;
    padding: 1em 0;

    &:hover {
      img {
        margin-left: .8em;
        transition: margin-left .3s;
      }
    }

    img {
      margin-left: .5em;
      transition: margin-left .3s;
    }
  }
}
